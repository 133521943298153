import {BrowserRouter as Router} from 'react-router-dom';
import Navbar from './Component/Navbar';
import Description from './Component/Description';
import Footer from './Component/Footer';
import './App.css';

function App() {
  return (
    <Router>
        <Description />
        <Footer />
      </Router>
  );
}

export default App;
