import { Disclosure } from '@headlessui/react'
import React from "react";

const Description = () => {
  return (
    <div class="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
        <div class="relative py-3 sm:max-w-xl sm:mx-auto">
        <div class="absolute inset-0 bg-gradient-to-r from-amber-500 to-amber-400 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div class="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
            <div class="max-w-md mx-auto">
                <div>
                    <img src="/logo512.png" class="h-20 sm:h-20" />
                </div>
                <div class="divide-y divide-gray-200">
                    <div class="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                        <div className="w-full px-4 pt-16">
                            <div className="w-full max-w-xxl p-2 mx-auto bg-white rounded-2xl">
                                <p class="text-center uppercase truncate text-3xl text-red-500">Under Construction</p>
                                <p class="break-all">KIREINA COFFEE is the brand of KIREINA coffee manufacturing, plc. 
                                    A private limited company founded 2021 in Addis Ababa, Ethiopia. 
                                    In the beginning of 2007 G.C. (2000 E.C), after many ideas and 
                                    thoughts, decisions were made to develop a unique idea for a new 
                                    generation of coffee cultivators.</p>
                                    
                                <Disclosure>
                                    <Disclosure.Panel>
                                        <p class="break-all">These decisions lead to the company founder and CEO, David Alexander 
                                            Morgan, migrating to the exotic homeland of coffee Arabica, the 
                                            country of Ethiopia. For generations past Ethiopian coffee Arabica 
                                            was and still is a renewable resource. Today Ethiopia has more than 
                                            3 million households engaged in coffee reaping. Most of who are 
                                            located in the oldest coffee forests on the planet. </p>
                                            <p class="break-all">A greater percentage of these forests are located in the newest 
                                                regional state within the Federal Democratic Republic of Ethiopia, 
                                                namely South West Ethiopia Region. The South West Ethiopia Region 
                                                was created on 23 November 2021 following a successful referendum 
                                                earlier in the year. The new region is a split off from the SNNPR 
                                                and consists of Kaffa, Sheka, Bench Sheko, Dawro, and West Omo Zones, 
                                                along with Konta special district</p>
                                            <p class="break-all">KIREINA COFFEE in collaboration with the coffee cooperatives of the 
                                                Bench Sheko zone aim to produce high varieties of carefully cultivated 
                                                high altitude indigenous Ethiopian coffee Arabica (green beans) within 
                                                the oldest coffee forests on the planet. All for an ever growing coffee 
                                                consuming consumer, who is always looking for a special cup of Joe? 
                                                The brand intends to take on coffee cultivation work in related areas, 
                                                but specifically the Sheko woreda of Bench Sheko zone of the newly formed 
                                                South West Ethiopia Regional State.</p>
                                    </Disclosure.Panel>
                                    <Disclosure.Button className="py-2">
                                        <p>
                                            Read More &rarr;
                                        </p>
                                    </Disclosure.Button>
                                    
                                </Disclosure>
                            </div>
                        </div>
                    </div>
                    <div class="pt-6 text-base leading-6 font-bold sm:text-lg sm:leading-7" >
                        <div class="flex items-center">
                            <p>Social Media and Contacts</p>
                        </div>
                        <div>
                            <div class="flex items-stretch">
                                <div class="py-4"><a href="https://www.youtube.com/channel/UCWGOSTcqxrF5Y3RRtfMtrmw/featured" class="text-cyan-600 hover:text-cyan-700"><img src="https://img.icons8.com/color/48/000000/youtube-play.png"/></a></div>
                                <div class="py-4"><a href="" class="text-cyan-600 hover:text-cyan-700"><img src="https://img.icons8.com/color/48/000000/linkedin.png"/></a></div>
                                <div class="py-4"><a href="https://t.me/kirinacoffee" class="text-cyan-600 hover:text-cyan-700"><img src="https://img.icons8.com/color/48/000000/telegram-app.png"/></a></div>
                                <div class="py-4"><a href="https://wa.me/0991722181" class="text-cyan-600 hover:text-cyan-700"><img src="https://img.icons8.com/color/48/000000/whatsapp--v4.png"/></a></div>
                                <div class="py-4"><a href="mailto: kireinacoffee@gmail.com" class="text-cyan-600 hover:text-cyan-700"><img src="https://img.icons8.com/color/48/000000/email-sign.png"/></a></div>
                            </div>
                            <div>
                                <p>Phone: +251-(0)-991-72-2181</p>
                                <p>Email: <a href = "mailto: kireinacoffee@gmail.com">kireinacoffee@gmail.com</a></p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="flex items-stretch font-bold py-6">
                            Powered by
                            <a href="http://linkedin.com/in/samuel-getnet-4810291b5"><img width="26" height="26" src="./logo.png" /></a>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        
    </div>
  );
}


export default Description;